<template>
  <div class="video-card"
    @click="videoCardClickEvent()" >
    <div class="video-item-body">
      <div class="ch-title-part">
        <div
          v-router-link="{ name: 'VideoDetail', params: post }"
          class="video-title cs-textstyle-paragraph-bold no-wrap cursor-pointer fs-12"
          :title="title"
        >
          {{ title }}
        </div>
      </div>
    <div>
      <app-video-thumbnail class="video-item-thumbnail" :video="post" />
    </div>
      <div v-if="post.user" class="video-meta">
        <app-avatar
          v-router-link="{ name: 'VideoProfile', params: post.user }"
          class="cursor-pointer"
          :name="post.user.displayname"
          :picture="post.user.profilePhotoURL"
          size="small"
        ></app-avatar>
        <div class="video-meta-info center-v">
          <div
            v-router-link="{ name: 'VideoProfile', params: post.user }"
            class="cs-textstyle-paragraph-small-bold cursor-pointer fs-smll"
          >
            <app-username :user="post.user" type="displayname" />
          </div>
          <div class="cs-textstyle-paragraph-small text-light fs-smll">
            {{ post.viewCount || 0 }}
            {{ post.viewCount === 1 ? 'view' : 'views' }} 
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $auth from '@/services/auth';
import $popups from '@/services/popups';
import $videosApi from '@/api/videos';
// Component
import AppAvatar from '@/components/general/Avatar.vue';
import AppVideoThumbnail from '@/components/video/VideoThumbnail.vue';
import AppContextMenu from '@/components/general/ContextMenu.vue';
import AppContextMenuItem from '@/components/general/ContextMenuItem.vue';
import AppUsername from '@/components/general/Username.vue';
import AppBookmarkButton from '@/components/post/buttons/BookmarkButton.vue';
import AppQueueButton from '@/components/post/buttons/QueueButton.vue';
import AppShareButton from '@/components/post/buttons/ShareButton.vue';
import pinnedVideoIcon from '@/assets/icons/pinnedvideoicon.svg';
import IncrementFeaturedPostCount from '@/gql/posts/IncrementFeaturedPostCount.gql'

export default {
  components: {
    AppAvatar,
    AppVideoThumbnail,
    AppContextMenu,
    AppContextMenuItem,
    AppBookmarkButton,
    AppQueueButton,
    AppShareButton,
    AppUsername,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    isFeaturedPost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
      isSuperAdmin: $auth.isAdmin(),
      isLoggedIn: false,
      pinnedVideoIcon,
    };
  },
  async created() {    // Check if the user is logged in when the component is created
    this.isLoggedIn = await $auth.isLoggedInAsUser();
  },
  computed: {
    isVideoLive() {
      return this.post && this.post.video && this.post.video.isLive;
    },
    title() {
      if (this.post) {
        return (
          (this.post.video && this.post.video.title) ||
          this.post.bodyText ||
          (this.post.video && this.post.video.name) ||
          'Untitled Video'
        );
      }
      return null;
    },
    postedByMe() {
      return (
        this.post &&
        this.post.user &&
        this.post.user.id === $auth.getUserId()
      );
    },
    embedCode() {
      return `<iframe width="710" height="400" allowfullscreen="true" src="${process.env.VUE_APP_SHARE_DOMAIN}/embed/${this.post.id}"></iframe>`;
    },
    shareLink() {
      return `${process.env.VUE_APP_SHARE_DOMAIN}/posts/${this.post.id}`;
    },
    safeToDisplay() {
      return this.postedByMe || this.post.safe;
    },
  },
  async mounted() {
    this.incrementFeaturedPostCount('channels_impressionscount');
  },
  methods: {
    
    async incrementFeaturedPostCount(property) {
      if(this.isFeaturedPost) {
        this.$apollo.mutate({
          mutation: IncrementFeaturedPostCount,
          variables: {
            postId: this.post.id,
            property
          },
        });
      }
    },
    async videoCardClickEvent() {
      this.incrementFeaturedPostCount('channels_clickscount');
      this.incrementFeaturedPostCount('channels_viewscount');
    }
  },
};
</script>

<style scoped>
.share-dropdown {
  width: 100%;
}
.share-dropdown-btn {
  background: none;
  border: none;
  flex-direction: column-reverse;
}
.video-card {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.video-item-thumbnail {
  width: 100%;
  height: 100px;
  object-fit: contain;
  background-color: black;
}

.video-title {
  padding: 10px;
}

.video-meta {
  padding: 10px 5px;
  display: flex;
  gap: 4px;
}

.video-meta-info {
  flex: 1;
}

.video-meta-extras {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.video-meta-extras__icons {
  display: flex;
  flex-direction: row;
  color: var(--cs-gray-05);
  align-items: center;
  gap: 8px;
}

.video-meta-extras__icons i {
  font-size: 12px;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
}
.ch-live-video {
  position: absolute;
  top: 46.5%;
  font-size: 12px;
  background: red;
  color: white;
  padding: 0 10px;
}
.ch-title-part {
  display: flex;
  max-width: 235px;
  justify-content: space-between;
}
.icon-overlay {
  width: 24px;
  transform: rotate(30deg);
  position: absolute;
  right: 16px;
  margin-top: 12px;
}
.fs-smll {
  font-size: 10px;
}
.fs-12 {
  font-size: 11px;
  margin-right: 10px;
}
.video-meta >>> .ch-avatar__content {
  height: 20px;
  width: 20px;
}
.center-v {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
